<!-- 合格证台账打印功能-->
<template>
<div style="margin: 5 auto;">
    <Form v-if="type ==2 || type ==3" ref="queryForm" :model="queryFormInline" label-position="right" :label-width="140" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
        <table width="100%">
            <tr>
                <td align="right" width="40%" >
                    <FormItem  label="请选择整车编码:" style="margin-bottom:5px;margin-top:10px">
                        <Select v-model="queryFormInline.vin">
                            <Option v-for="(item,index) in vins" :value="item" :key="index">{{item}}</Option>
                        </Select>
                    </FormItem>
                </td>
                <td align="left">
                    <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                        <Button type="primary" @click="handleQuery()" >查询</Button>    
                        <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                    </div>
                </td>
            </tr>
        </table>
    </Form>
    <div style="margin:0 20px;float:right">
        <Button type="warning"  @click="previewdata">打印预览</Button>
        <Button type="primary" style="margin-left: 20px;" @click="printdata">直接打印</Button>
    </div>
    
    <div id="printdiv" style="margin:0 auto;margin-top:1px;width:1300px;height:920px;clear: both;overflow-y: scroll;">
        <div id="contentdiv" v-if="doc !=null"  v-html="doc.doccontent"></div>
    </div>
    <div>

    </div>
</div>
</template>
 <script>
    import {getPrinters,public_print,public_print_with_dir_on_one_page,public_preview_with_dir_on_one_page} from '@/zsjs/zskjprinter.js'
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'CcjyPrint',
        //type: 0- 首件  1-末件  2-100%  3-流程卡
        props:['type','picidata'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg,
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:1
                },
                queryFormInline :{
                    vin: null
                },
                doc:null,
                vins:[]
            }
        },
        mounted(){
            console.log('ccjyprint mounted type='+this.type)
            if(this.type == 2 || this.type == 3){
                this.handleQueryVins()
            }
            else{
                this.handleQuery()
            }

        },
        methods:{
            handleQuery(){
                let postdata={
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    type: this.type,
                    modelno: this.picidata.modelno,
                    madedate: new Date(this.picidata.madedate).Format('yyyy-MM-dd')
                }

                if(this.queryFormInline.vin !=null){
                    postdata.vin = this.queryFormInline.vin
                }

                this.$axios({
                    method:'post',
                    url:'/api/checkdept/doc/ccjy/get',
                    data: postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.doc = res.data.data[0]
                        this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            handleQueryVins(){
                let postdata={
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    modelno: this.picidata.modelno,
                    madedate: new Date(this.picidata.madedate).Format('yyyy-MM-dd'),
                }

                this.$axios({
                    method:'post',
                    url:'/api/checkdept/doc/ccjy/getvins',
                    data: postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.vins = res.data.data
                        console.log(this.vins)
                        //this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            getexpectdate(m){
                return new Date().getFullYear()+'-'+this.cgdetail.mademonth+'-22'
            },
            getpurchasedate(m){
                return new Date().getFullYear()+'-'+this.cgdetail.mademonth+'-15'
            },
            previewdata(){
                public_preview_with_dir_on_one_page("printdiv",1,this.doc.direction);
            },
            printdata(){
                public_print_with_dir_on_one_page("printdiv",1,this.doc.direction);
            },
            reload(){
                if(this.type == 2){
                    this.handleQueryVins()
                }
                else{
                    this.handleQuery()
                }
            }
        },
        watch:{
            modelcfg (n){
                console.log(this.modelcfg)
            },
            picidata:{
                handler: function(val, oldVal){
                    if(val!=null){
                        if(this.type == 2){
                            this.handleQueryVins()
                        }
                        else{
                            this.handleQuery()
                        }
                    }
                },
                // 深度观察监听
                deep: true
            }
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}
</style>