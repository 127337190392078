<!-- 合格证台账打印功能-->
<template>
<div style="margin: 5 auto;">
    <div style="margin:0 20px;float:right">
        <Button type="warning"  @click="previewdata">打印预览</Button>
        <Button type="primary" style="margin-left: 20px;" @click="printdata">直接打印</Button>
    </div>
    <div id="printdiv" style="margin:0 auto;margin-top:1px;width:1300px;height:920px;clear: both;">
        <div id="contentdiv" v-if="doc !=null"  v-html="doc.doccontent"></div>
    </div>
</div>
</template>
 <script>
    import {getPrinters,public_print,public_print_with_dir_on_one_page,public_preview_with_dir_on_one_page} from '@/zsjs/zskjprinter.js'
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'GcjyPrint',
        //type: 10- 预装  20-线装
        props:['type','picidata'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg,
                doc:null
            }
        },
        mounted(){
            this.handleQuery()
        },
        methods:{
            handleQuery(){
                let postdata={
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    modelno: this.picidata.modelno,
                    mcount: this.picidata.mcount,
                    madedate: new Date(this.picidata.madedate).Format('yyyy-MM-dd')
                }

                let posturl
                if(this.type == 10){
                    posturl = '/api/checkdept/doc/gcjyyz/get'
                }
                else if(this.type == 20){
                    posturl = '/api/checkdept/doc/gcjyxz/get'
                }
                else{
                    this.$Message.error({content:'发生错误，请退出重试！',duration:2})
                    return
                }

                this.$axios({
                    method:'post',
                    url:posturl,
                    data: postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res)
                        this.doc = res.data.data
                        this.$Message.success({content:res.data.msg, duration:3})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));  
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            previewdata(){
                public_preview_with_dir_on_one_page("printdiv",1,2);
            },
            printdata(){
                public_print_with_dir_on_one_page("printdiv",1,2);
            }
        },
        watch:{
            modelcfg (n){
                console.log(this.modelcfg)
            }
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}
</style>