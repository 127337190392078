<template>
    <div style="margin:0 auto">
        <Modal v-model="showrecord" title="查看出厂检验" width="1350px" @on-visible-change="onmodalvisit" scrollable>
            <ccjyview v-if="showrecord==true" v-bind:type="viewtype" v-bind:picidata="picidata" />
        </Modal>
        <Modal v-model="showgcjy" title="查看过程检验记录" width="1350px" @on-visible-change="ongcjymodalvisit" scrollable>
            <gcjyview v-if="showgcjy==true" v-bind:type="viewtype" v-bind:picidata="picidata" />
        </Modal>
        <Form ref="ycwjQueryForm" :model="formInline" :rules="ruleInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <FormItem  label="车辆型号:" prop="parentid" style="margin-bottom:5px;margin-top:10px">
                            <i-select v-model="formInline.parentid">
                                <i-option value=null key="0">请先选择车型</i-option>
                                <i-option v-if="modellist !=null" v-for="(item,index) in modellist" :value="item.id" :key="item.id">车型{{item.modelno}}</i-option>
                            </i-select>
                        </FormItem>
                    </td>
                    <td align="right" width="23%">
                        <FormItem  label="选择年份:" prop="madeyear" style="margin-bottom:5px;margin-top:10px">
                            <i-select v-model="formInline.madeyear" @on-change="onyearselect">
                                <i-option value=null key="0">请选择年份</i-option>
                                <i-option v-if="modellist !=null" v-for="item in [2020, yearnow()]" :value="item" :key="item">{{item}}年</i-option>
                            </i-select>
                        </FormItem>
                    </td>
                    <td align="right" width="23%">
                        <FormItem  label="选择月份:" prop="mademonth" style="margin-bottom:5px;margin-top:10px">
                            <i-select v-model="formInline.mademonth">
                                <i-option value=null key="0">全部月份</i-option>
                                <i-option v-if="modellist !=null" v-for="item in monthdata" :value="item" :key="item">{{item}}月</i-option>
                            </i-select>
                        </FormItem>
                    </td>
                    <td align="left">
                        <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                            <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">车辆批次</label>  
        </div> 
        <Table :columns="tblcolumns" :data="tbldata" stripe border style="margin-top: 10px;">
            <template slot-scope="{ row, index }" slot="title" >
                <div >
                    <p >{{new Date(row.madedate).Format('yyyy-MM-dd')}}批次车辆</p>
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="ccjy" >
                <div  class="opbar">
                    <Button type="success" size="small" ghost @click="viewfirst(index)">首件</Button>      
                    <Button type="primary" size="small" ghost @click="viewlast(index)">末件</Button>  
                    <Button type="warning" size="small" ghost @click="viewevery(index)">100%</Button> 
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="gcjy" >
                <div  class="opbar">
                    <Button type="success" size="small"  @click="viewpreinstall(index)">预装</Button>      
                    <Button type="primary" size="small"  @click="viewlineinstall(index)">线装</Button>  
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="lck" >
                <div  class="opbar">
                    <Button type="error" size="small" ghost @click="viewlck(index)">查看</Button>      
                </div>
            </template>
        </Table>
    </div>
</template>

<script>
    import CcjyPrint from '@/views/business/workshop/checkdept/qc/CcjyPrint'
    import GcjyPrint from '@/views/business/workshop/checkdept/qc/GcjyPrint'
export default {
    name:'Ccjy',
    data(){
        return {
            logoninfo: this._self.$root.$store.state.LogonInfo,
            companyid: this._self.$root.$store.state.LogonInfo.companyid,
            modellist:this._self.$root.$store.state.ModelListInfo,
            formInline:{
                parentid:null,
                mademonth:null,
                madeyear:null,
            },
            ruleInline: {
                parentid: [
                    { required: true, message: '请选择车型', trigger: 'blur' },
                ],
                madeyear:[
                    { required: true, message: '请选择年份', trigger: 'blur' },
                ],
                mademonth:[
                    { required: true, message: '请选择月份', trigger: 'blur' },
                ],
            },
            tblcolumns: [
                {
                    type: 'index',
                    width: 60,
                    align: 'center'
                },
                {
                    title: '车辆批次',
                    slot: 'title',
                },
                {
                    title: '数量(辆)',
                    key: 'mcount',
                },
                {
                    title: '出厂检验',
                    slot: 'ccjy',
                    align: 'center'
                },
                {
                    title: '过程检验',
                    slot: 'gcjy',
                    align: 'center'
                },
                {
                    title: '流程卡',
                    slot: 'lck',
                    align: 'center'
                }
            ],
            tbldata: [],
            monthdata:[],
            modelno:null,
            showrecord:false,
            showgcjy:false,
            viewtype:null,
            picidata:null
        }
    },
    mounted(){
        //this.handleQuery();
    },
    methods:{
        viewfirst(index){
            this.picidata = this.tbldata[index]
            this.viewtype = 0
            this.showrecord = true
        },
        viewlast(index){
            this.picidata = this.tbldata[index]
            this.viewtype = 1
            this.showrecord = true
        },
        viewevery(index){
            this.picidata = this.tbldata[index]
            this.viewtype = 2
            this.showrecord = true
        },
        viewpreinstall(index){
            this.picidata = this.tbldata[index]
            this.viewtype = 10
            this.showgcjy = true
        },
        viewlineinstall(index){
            this.picidata = this.tbldata[index]
            this.viewtype = 20
            this.showgcjy = true
        },
        viewlck(index){
            this.picidata = this.tbldata[index]
            this.viewtype = 3
            this.showrecord = true
        },
        getmodelnobyid(id){
            for(let i=0;i<this.modellist.length;i++){
                if(this.modellist[i].id == id){
                    return this.modellist[i].modelno
                }
            }
            return null
        },
        handleQuery(){

            if(this.formInline.parentid == null){
                this.$Message.error({content:'请先选择车型！',duration:3})
                return
            }
            if(this.formInline.madeyear == null){
                this.$Message.error({content:'请先选择年份！',duration:3})
                return
            }
            if(this.formInline.mademonth == null){
                this.$Message.error({content:'请先选择月份！',duration:3})
                return
            }
            let postdata={
                companyid: this.companyid,
                modelno: this.getmodelnobyid(this.formInline.parentid),
                madeyear: this.formInline.madeyear,
                mademonth: this.formInline.mademonth
            }

            if(postdata.mademonth == 'null' || postdata.mademonth == null){
                postdata.mademonth = -1
            }
            this.modelno = postdata.modelno

            this.$axios({
                method:'post',
                url:'/api/checkdept/doc/ccjy/list',
                data: postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.tbldata = res.data.data
                    console.log(this.tbldata)
                    this.$Message.success({content:res.data.msg, duration:3})
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        handleReset(){
            this.formInline.mademonth = null
            this.formInline.parentid = null
            this.handleQuery()
        },
        onmodalvisit(n){
            if(n==false){
                this.viewtype = null
                this.picidata = null
            }
        },
        ongcjymodalvisit(n){
            if(n==false){
                this.viewtype = null
                this.picidata = null
            }
        },
        onyearselect(y){
            if(this.formInline.parentid == null){
                this.$Message.error({content:'请先选择车型！',duration:3})
                return
            }

            this.monthdata = []
            if(y==null || y==''){
                return
            }

            let postdata={
                companyid: this.companyid,
                modelno: this.getmodelnobyid(this.formInline.parentid),
                madeyear: y
            }

            this.$axios({
                method:'post',
                url:'/api/checkdept/doc/ccjy/listmonth',
                data: postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
            }).then(function(res){
                if(res.data.resultCode ==0){
                    console.log(res)
                    this.monthdata = res.data.data
                    console.log(this.monthdata)
                }
                else{
                    this.$Message.error({content:res.data.data.msg, duration:3})
                }
            }.bind(this));  
        },
        yearnow(){
            return new Date().getFullYear()
        }
    },
    components:{
        'ccjyview':CcjyPrint,
        'gcjyview':GcjyPrint
    }
}
</script>
<style scoped>
</style>
 